import React, { Component } from "react";
import queryString from "query-string";

// seo
import Seo from "../components/Seo";
// app header
import AppHeader from "../components/AppHeader/AppHeader";

// components
import QuizHome from "../components/RiskQuiz/QuizHome";
import RiskQuiz from "../components/RiskQuiz/RiskQuiz";
import QuizForm from "../components/RiskQuiz/QuizForm";
import QuizResult from "../components/RiskQuiz/QuizResult";

class RiskQuizPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      questionResult: {},
      quizResult: {},
      user: {},
    };
  }

  handleNextStep = nextStep => {
    this.setState({ step: nextStep });
  };

  submitQuiz = async values => {
    const totalScore =
      ((values.age +
        values.bmi +
        values.diabetecHistory +
        values.eatJunkFood +
        values.physicalActivity +
        values.sleepPattern +
        values.waist) /
        160) *
      100;
    // const totalScore = Math.floor(score);

    if (totalScore < 31) {
      let finalData = {
        totalScore: totalScore,
        result: "LOW RISK",
        barColor: "#00A651",
        CTA: false,
      };
      this.setState({
        quizResult: finalData,
        questionResult: values,
      });
    } else if (totalScore >= 31 && totalScore < 44) {
      let finalData = {
        totalScore: totalScore,
        result: "MODERATE RISK",
        barColor: "#FF5A00",
        CTA: true,
      };
      this.setState({
        quizResult: finalData,
        questionResult: values,
      });
    } else if (totalScore >= 44 && totalScore < 61) {
      let finalData = {
        totalScore: totalScore,
        result: "HIGH RISK",
        barColor: "#F43636",
        CTA: true,
      };
      this.setState({
        quizResult: finalData,
        questionResult: values,
      });
    } else {
      let finalData = {
        totalScore: totalScore,
        result: "VERY HIGH RISK",
        barColor: "#D5190C",
        CTA: true,
      };
      this.setState({
        quizResult: finalData,
        questionResult: values,
      });
    }
    setTimeout(() => {
      this.handleNextStep(2);
    }, 3000);
  };

  handleUser = user => {
    this.setState({ user });
  };

  render() {
    const { step, questionResult, quizResult, user } = this.state;
    const { appHeader } = queryString.parse(this.props.location.search);

    return (
      <div>
        <Seo
          title="Diabetes Risk Assessment Quiz | Twin"
          description="The quiz will help you in taking action against diabetes."
        />

        {appHeader === "mobile" && (
          <section>
            <AppHeader text="RISK QUIZ" isBack={true} />
          </section>
        )}

        <div>
          {step === 0 && (
            <QuizHome
              appForDxStatus={appHeader === "mobile"}
              handleNextStep={this.handleNextStep}
            />
          )}

          {step === 1 && (
            <RiskQuiz
              appForDxStatus={appHeader === "mobile"}
              handleNextStep={this.handleNextStep}
              submitQuiz={this.submitQuiz}
            />
          )}

          {step === 2 && (
            <QuizForm
              handleNextStep={this.handleNextStep}
              appForDxStatus={appHeader === "mobile"}
              handleUser={this.handleUser}
              questionResult={questionResult}
            />
          )}

          {step === 3 && (
            <>
              <QuizResult
                handleNextStep={this.handleNextStep}
                quizResult={quizResult}
                user={user}
                questionResult={questionResult}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}

const withContext = props => <RiskQuizPage {...props} />;

export default withContext;
