import React from "react";
import PropTypes from "prop-types";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const propTypes = {
  value: PropTypes.number,
  text: PropTypes.number,
  strokeWidth: PropTypes.number,
  circleRatio: PropTypes.number,
  textColor: PropTypes.string,
  pathColor: PropTypes.string,
};

const defaultProps = {
  value: 20,
  text: 20,
  strokeWidth: 10,
  circleRatio: 0.75,
  textColor: "red",
  pathColor: "turquoise",
};

const SemiCircleGraph = props => {
  return (
    <>
      <CircularProgressbar
        value={props.value}
        text={`${props.text}%`}
        strokeWidth={props.strokeWidth}
        circleRatio={props.circleRatio}
        styles={buildStyles({
          rotation: 1 / 2 + 1 / 8,
          strokeLinecap: "butt",
          textColor: props.textColor,
          pathColor: props.pathColor,
        })}
      />
    </>
  );
};

SemiCircleGraph.propTypes = propTypes;
SemiCircleGraph.defaultProps = defaultProps;

export default SemiCircleGraph;

// How To Use

// Ex1: <SemiCircleGraph />
// Ex2: <SemiCircleGraph value={20} text={20} strokeWidth={10} circleRatio={0.75} textColor="blue" pathColor="red" />
