import React from "react";
//images
import { StaticImage } from "gatsby-plugin-image";

import SemiCircleGraph from "../SemiCircleGraph/SemiCircleGraph";

//css
import * as styles from "./RiskQuiz.module.css";

const QuizBanner = ({ user, quizResult }) => {
  return (
    <div className="bgLightgray">
      <div className="container twinContainer">
        <div className="row">
          <div
            className={`col-12 d-flex justify-content-center ${styles.resultCon}`}
          >
            <StaticImage
              src={"../../images/logo-v1.png"}
              placeholder="blurred"
              alt="twinLogo"
            />
          </div>
          <div className="col-12">
            <div
              className={`col-12 col-md-8 offset-md-2 d-flex justify-content-md-center ${styles.resultConTxt1}`}
            >
              <p className={`${styles.resultConTxt} px-2  px-md-5`}>
                Hey
                <span className={`${styles.userName}`}>
                  {" "}
                  {user && user.name ? user.name : "testing"},
                </span>{" "}
                your risk for having type 2 diabetes is
              </p>
            </div>
            <div className="offset-md-2"></div>
          </div>
          <div className="col-12">
            <div className="col-12 d-flex justify-content-center">
              {/* <span className={styles.days}>
                {(quizResult && Math.floor(quizResult.revTime)) || 39} days
              </span> */}
              <div className="row">
                <div className="col-12">
                  <div className={styles.result_2_days}>
                    <SemiCircleGraph
                      value={Math.floor(quizResult.totalScore)}
                      text={Math.floor(quizResult.totalScore)}
                      strokeWidth={20}
                      circleRatio={0.75}
                      textColor={quizResult.barColor}
                      pathColor={quizResult.barColor}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className={styles.risk}
                    style={{ color: quizResult.barColor }}
                  >
                    {quizResult.result && quizResult.result}
                  </div>
                </div>
                <div className="col-md-6 offset-md-3 col-12">
                  <div className={styles.resultInfo}>
                    Right now, your risk for having type 2 diabetes is{" "}
                    {quizResult.result && quizResult.result.toLowerCase()}.
                    However, your risk changes over time. Be sure to schedule
                    regular blood tests and check-ups with your doctor.
                  </div>
                </div>
                <div className="col-md-6"></div>
              </div>
            </div>
            {/* <div className={styles.curve}>
                <StaticImage
                  src={"../../images/quiz/curve.png"}
                  alt="curve"
                  placeholder="blurred"
                />
              </div> */}
          </div>
        </div>
        {/* <div className="col-12 d-flex justify-content-center">
              <span className={styles.resultWarn}>
                here is how your potential reversal graph looks like{" "}
              </span>
            </div> */}
        {/* <div className="col-12 d-flex justify-content-center">
              <span className={styles.chartImg}>
                <StaticImage
                  src={"../../images/quiz/chart.png"}
                  alt="chart"
                  placeholder="blurred"
                />
              </span>
            </div> */}
      </div>
    </div>
  );
};

export default QuizBanner;
