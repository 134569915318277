import React, { Component } from "react";

//component
// import QuizResultForm from "./QuizResultForm";
// import SemiCircleGraph from "../SemiCircleGraph/SemiCircleGraph";
// import FirstSlide from "../DiabetesQuiz/FirstSlide";

import Inclusion from "../Home/Inclusion/Inclusion";
import SuccessStories from "../Home/SuccessStories/SuccessStories";
import QuizBanner from "./QuizBanner";
import QuizCta from "../DiabetesQuiz/QuizCta";

class QuizResult extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user, quizResult } = this.props;

    return (
      <>
        <QuizBanner user={user} quizResult={quizResult} />
        <QuizCta
          ctaLink={"/wbdt-care-plan"}
          ctaEvent={"risk_quiz_result_CTA"}
          ctaAction={{ action: "user clicks on book pack CTA" }}
        />
        <SuccessStories
          carouselId="riskQuizSuccessStories"
          bgColor={"bgLightpink"}
          titleText="Numerous people are reversing diabetes with Whole Body Digital Twin™"
        />
        <Inclusion />
      </>
    );
  }
}

export default QuizResult;
