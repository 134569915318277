// extracted by mini-css-extract-plugin
export var Hurry = "RiskQuiz-module--Hurry--zj+zN";
export var actionContainer = "RiskQuiz-module--actionContainer--Jbg88";
export var belowTxt = "RiskQuiz-module--belowTxt--ojy-C";
export var belowTxt2 = "RiskQuiz-module--belowTxt2--G3yps";
export var btnCon = "RiskQuiz-module--btnCon--oqf0b";
export var btntxt = "RiskQuiz-module--btntxt--KbiZR";
export var chartImg = "RiskQuiz-module--chartImg--EqA6T";
export var con = "RiskQuiz-module--con--KOwX+";
export var conHeight = "RiskQuiz-module--conHeight--XjLdN";
export var conWithDxHeight = "RiskQuiz-module--conWithDxHeight--bdOz8";
export var connHeight = "RiskQuiz-module--connHeight--tjdnR";
export var connnHeight = "RiskQuiz-module--connnHeight--j0bI-";
export var ctaBtn = "RiskQuiz-module--ctaBtn--EnnO-";
export var ctaCon = "RiskQuiz-module--ctaCon--DwfFe";
export var ctaConTxt = "RiskQuiz-module--ctaConTxt--a6DIq";
export var curve = "RiskQuiz-module--curve--jvlTr";
export var days = "RiskQuiz-module--days--g1-KL";
export var designation = "RiskQuiz-module--designation--W5IMU";
export var designationSecond = "RiskQuiz-module--designationSecond--Mv7Yx";
export var details = "RiskQuiz-module--details--Xogy1";
export var dr1Img = "RiskQuiz-module--dr1Img--nS-V1";
export var drName = "RiskQuiz-module--drName--e82a2";
export var formInput = "RiskQuiz-module--formInput--CTJQu";
export var form_heading = "RiskQuiz-module--form_heading--ADMto";
export var form_p = "RiskQuiz-module--form_p--mbm4a";
export var giftIcon = "RiskQuiz-module--giftIcon--xKOoV";
export var heading_text = "RiskQuiz-module--heading_text--id9ZA";
export var logoConHeight = "RiskQuiz-module--logoConHeight--cKqhu";
export var logoImg = "RiskQuiz-module--logoImg--7bDpM";
export var maleImg = "RiskQuiz-module--maleImg--my-vC";
export var option = "RiskQuiz-module--option--fK5Pc";
export var option_selected = "RiskQuiz-module--option_selected--67EIL";
export var priceTxt = "RiskQuiz-module--priceTxt--6+Sxs";
export var progressBar = "RiskQuiz-module--progressBar--gIh-9";
export var progressHighBar = "RiskQuiz-module--progressHighBar--cSh9G";
export var question = "RiskQuiz-module--question--R7aBb";
export var resultCon = "RiskQuiz-module--resultCon--iSpKK";
export var resultConTxt = "RiskQuiz-module--resultConTxt--JQB3g";
export var resultConTxt1 = "RiskQuiz-module--resultConTxt1--19dYR";
export var resultInfo = "RiskQuiz-module--resultInfo--LAIQk";
export var resultWarn = "RiskQuiz-module--resultWarn--QSkIH";
export var result_2_days = "RiskQuiz-module--result_2_days--vu-FY";
export var result_heading = "RiskQuiz-module--result_heading--gSjcr";
export var result_p = "RiskQuiz-module--result_p--s+Bn6";
export var risk = "RiskQuiz-module--risk--D4GBQ";
export var strikRs = "RiskQuiz-module--strikRs--fbDbm";
export var submitBtn = "RiskQuiz-module--submitBtn--TwwiV";
export var text1 = "RiskQuiz-module--text1--4JKDd";
export var text2 = "RiskQuiz-module--text2--Y9RO4";
export var title1 = "RiskQuiz-module--title1--RReJu";
export var title2 = "RiskQuiz-module--title2--AfXlq";
export var treated = "RiskQuiz-module--treated--aYSGu";
export var treated2 = "RiskQuiz-module--treated2--+JG-l";
export var userName = "RiskQuiz-module--userName--Bf+B-";
export var webDetailsCon = "RiskQuiz-module--webDetailsCon--7+M0q";
export var webDr2Con = "RiskQuiz-module--webDr2Con--3Z6rT";
export var webDrCon = "RiskQuiz-module--webDrCon--Asiz6";
export var webiCon = "RiskQuiz-module--webiCon--qamnL";
export var webiConn = "RiskQuiz-module--webiConn--7I8U7";
export var webiDetailImg = "RiskQuiz-module--webiDetailImg--yzT52";
export var webiTit = "RiskQuiz-module--webiTit--0AHJi";
export var webiTit2 = "RiskQuiz-module--webiTit2--8fbNe";
export var webiTxt1 = "RiskQuiz-module--webiTxt1--KpVaP";
export var webititle = "RiskQuiz-module--webititle--qb6h7";
export var webititle2 = "RiskQuiz-module--webititle2--MRZnh";
export var whiteFontArrow = "RiskQuiz-module--whiteFontArrow--nMEAw";
export var yoe = "RiskQuiz-module--yoe--gi8PG";