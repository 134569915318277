import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Loader from "react-loader-spinner";

// services
import { sendToLSQ } from "../../services/LeadSquared";

// css
import * as styles from "./RiskQuiz.module.css";

// images
import { StaticImage } from "gatsby-plugin-image";
import { sendToCT } from "../../services/Clevertap";

const QuizForm = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState("");

  const onSubmit = async data => {
    // LeadSquared
    let leadSquaredParams = {
      FirstName: data.name,
      Phone: data.phone,
      EmailAddress: data.email,
      mx_Website_CTA: "risk_quiz",
      mx_Latest_Source: "New Website",
    };

    try {
      setLoading(true);
      setServerError("");
      // let res = await axios(options);
      // res = res.data;
      await sendToLSQ(leadSquaredParams);
      setLoading(false);
      props.handleUser(data);
      sendToCT("risk_quiz_successful", data);
      props.handleNextStep(3);
    } catch (error) {
      setLoading(false);
      setServerError("Try again");
      console.error("quiz server error", error);
    }
  };

  return (
    <div className="bgLightgray">
      <div className="container twinContainer">
        <div
          className={`row align-items-center ${
            props.appForDxStatus ? styles.conWithDxHeight : styles.conHeight
          }`}
        >
          <div className="row">
            <div className="col-12 d-none d-md-flex justify-content-end">
              <div className="col-3 offset-9">
                <span
                  onClick={() => props.handleNextStep(0)}
                  role={"presentation"}
                  className="smallIcon cursorPointer icon-cross"
                />
              </div>
              {/* <div className="col-4"></div> */}
            </div>
            <div className="col-md-6 offset-md-3 col-12 pe-0">
              <div className={`col-12 pt-4`}>
                <div className="col-12">
                  <p className={styles.form_heading}>
                    Your Diabetes Risk Score is ready
                  </p>
                </div>
                <div className="col-md-8 offset-md-2 col-12">
                  <div className={styles.form_p}>
                    Please enter your name and phone number to review your
                    program plan
                  </div>
                </div>
                <div className="col-md-2"></div>
              </div>
              <div className="col-md-12 col-12">
                <div className="ps-md-5 pe-md-5">
                  <form
                    name="bookTrial"
                    onSubmit={handleSubmit(onSubmit)}
                    className={`mt-3`}
                  >
                    <div className={`form-group mb-3 ${styles.formInput}`}>
                      <label htmlFor="name">Full Name* </label>
                      <input
                        className={
                          errors.name
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Full name"
                        id="name"
                        type="text"
                        {...register("name", {
                          required: "Please enter your name",
                          pattern: {
                            value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                            message: "Please enter your valid name",
                          },
                          maxLength: { value: 50, message: "Name is too long" },
                        })}
                      />
                      {errors.name && (
                        <span className="invalid-feedback">
                          {errors.name.message}
                        </span>
                      )}
                    </div>
                    <div className={`form-group mb-3 ${styles.formInput}`}>
                      <label htmlFor="name">Phone* </label>

                      <input
                        className={`form-control letterSp1 ${
                          errors.phone ? "is-invalid" : ""
                        }`}
                        placeholder="Phone number"
                        id="phone"
                        type="text"
                        inputMode="numeric"
                        maxLength="10"
                        {...register("phone", {
                          required: "Please enter your mobile number",
                          pattern: {
                            value:
                              /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                            message: "Please enter your valid phone number",
                          },
                          maxLength: {
                            value: 10,
                            message: "Invalid phone number",
                          },
                        })}
                      />
                      {errors.phone && (
                        <span className="invalid-feedback">
                          {errors.phone.message}
                        </span>
                      )}
                    </div>

                    <div className={`form-group mb-3 ${styles.formInput}`}>
                      <label htmlFor="name">Email* </label>

                      <input
                        className={
                          errors.email
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Email id"
                        id="email"
                        type="email"
                        {...register("email", {
                          required: "Please enter your email",
                          pattern: {
                            value:
                              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                            message: "Please enter your valid email",
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="invalid-feedback">
                          {errors.email.message}
                        </span>
                      )}
                    </div>

                    <div className="col-12 mt-2">
                      <div className="col-12 cursorPointer">
                        <button
                          type="submit"
                          className={`gradientBtnWithArrow w-100 pt-2 pb-2 ${styles.submitBtn}`}
                        >
                          {!loading ? (
                            <>
                              <p className={styles.btntxt}>
                                Show my Risk Score{" "}
                              </p>
                              <span
                                className={`mediumIcon icon-arrow-forward ${styles.whiteFontArrow}`}
                              />
                            </>
                          ) : (
                            <span className={"pt-1 pb-1"}>
                              <Loader
                                type="ThreeDots"
                                color="#FFF"
                                height={30}
                                width={30}
                                visible={loading}
                              />
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                    <div className="mt-2 text-center text-danger">
                      {serverError}
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
        <div className={`row ${styles.logoConHeight}`}>
          <div className="col-12 d-flex justify-content-center">
            <div className={styles.logoImg}>
              <StaticImage
                src={"../../images/logo-v1.png"}
                placeholder="blurred"
                alt="twinLogo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizForm;
